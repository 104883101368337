* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: #000;
}
h1,
h2 {
  font-family: "Gen Vision Font";
  letter-spacing: 2;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
